import React from 'react';
import SEO from '../components/seo';
import { Link } from 'gatsby';

const NotFoundPage = () => (
	<>
		<SEO title="404: Не найдено" />
		<h1>Такой страницы не существует</h1>
		<p>Зато существую туры, которые придутся вам по душе!</p>
		<Link to="/">Смотреть</Link>
	</>
);

export default NotFoundPage;
